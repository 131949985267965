"use client";

import cn from "clsx";
import { CSSProperties } from "react";

type ScrollToProps = {
  label: string;
  elementID: string;
  style?: CSSProperties;
  className?: string;
};

export function ScrollTo({ label, elementID, style, className = "" }: ScrollToProps) {
  const scrolltoHash = function (element_id: string) {
    const element = document.getElementById(element_id);
    element?.scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "start",
    });
  };

  return (
    <div
      className={cn("cursor-pointer scroll-smooth font-normal italic underline", className)}
      onClick={() => {
        scrolltoHash(elementID);
      }}
      style={style}
    >
      {label}
    </div>
  );
}
