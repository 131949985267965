"use client";

import { ReactNode, useEffect, useState } from "react";
import { SwiperSlide } from "swiper/react";

import { Carousel } from "@/common/components/carousel/carousel";

const breakpoints = {
  "1720": {
    slidesPerView: 8,
    spaceBetween: 28,
  },
  "1400": {
    slidesPerView: 7,
    spaceBetween: 28,
  },
  "1025": {
    slidesPerView: 6,
    spaceBetween: 28,
  },
  "768": {
    slidesPerView: 5,
    spaceBetween: 20,
  },
  "500": {
    slidesPerView: 3,
    spaceBetween: 12,
  },
  "370": {
    slidesPerView: 3,
    spaceBetween: 12,
  },
  "0": {
    slidesPerView: 1,
    spaceBetween: 12,
  },
};

export function CardSwiperRow({ cards }: { cards: ReactNode[] }) {
  const [items, setItems] = useState<ReactNode[]>([]);

  useEffect(() => {
    setItems(cards);
  }, [cards]);

  return (
    <div className="relative mx-auto">
      <Carousel
        loop={false}
        centeredSlides={false}
        breakpoints={breakpoints}
        autoplay={{
          delay: 3000,
        }}
        buttonGroupClassName="-mt-7"
      >
        {items.map((card, index) => (
          <SwiperSlide className="carouselItem" key={index}>
            {card}
          </SwiperSlide>
        ))}
      </Carousel>
    </div>
  );
}
