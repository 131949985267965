import { ContentfulLivePreview } from "@contentful/live-preview";

type RemoveCollectionSuffix<T extends Record<string, any>> = {
  [K in keyof T as K extends `${infer Prefix}Collection` ? Prefix : K]: T[K];
};

export type PreviewOptions<T extends object | unknown = null> = {
  entryId?: string;
  fieldId: T extends object ? keyof RemoveCollectionSuffix<T> : string;
  locale?: string;
};

export function getPreviewProps<T extends object | unknown = null>(
  props: PreviewOptions<T>,
): ReturnType<(typeof ContentfulLivePreview)["getProps"]> | Record<string, never> {
  const { entryId, ...other } = props || {};
  if (entryId === undefined) return {};
  return ContentfulLivePreview.getProps({
    entryId,
    ...other,
  });
}
