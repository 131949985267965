import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS } from "@contentful/rich-text-types";

export function ListItem({ node }: { node: any }) {
  const UntaggedChildren = documentToReactComponents(node, {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => children,
      [BLOCKS.LIST_ITEM]: (node, children) => children,
    },
  });
  return <li>{UntaggedChildren}</li>;
}
