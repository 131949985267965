"use client";

import Button from "@/common/components/button";
import { useTranslations } from "next-intl";

declare global {
  interface Window {
    zE: (
      action: string,
      value: string,
      handler?: (callback: (data: string | number) => void) => void,
    ) => void;
  }
}

export function ChatButton() {
  const openChatWindow = () => window?.zE?.("messenger", "open");
  const t = useTranslations("common");

  return (
    <Button
      variant="secondary"
      className="h-12 !w-36 !rounded-lg !text-sm !font-semibold uppercase md:!text-base"
      onClick={openChatWindow}
    >
      {t("button-chat-services")}
    </Button>
  );
}
