"use client";

import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS } from "@contentful/rich-text-types";
import Image from "next/image";
import { SwiperSlide } from "swiper/react";

// import { getLivePreviewProps } from "@/clients/contentful";
import { CtfEntry, getPath, TypeHeroCarouselComponentSkeleton } from "@/clients/contentful";
import Button from "@/common/components/button";
import { Carousel } from "@/common/components/carousel/carousel";
import { OptionalLink } from "@/common/components/optional-link";
import { useSsrCompatible } from "@/common/hooks/use-ssr-compatible";
import { useWindowSize } from "@/common/hooks/use-window-size";
import { defaultDocumentToReactComponentsOptions } from "@/features/contentful/default-document-to-react-component-options";

export function HeroCarousel({ entry }: { entry: CtfEntry<TypeHeroCarouselComponentSkeleton> }) {
  const slides = entry.fields.slides;
  const { width } = useSsrCompatible(useWindowSize(), { width: 0, height: 0 });

  return (
    <div
      className="relative mx-auto mb-12 overflow-hidden md:mb-14 xl:mb-16"
      // Todo: separate getLivePreviewProps from the server-side code
      // {...getLivePreviewProps(entry, "slides")}
    >
      <Carousel
        centeredSlides={width >= 1280}
        loop={true}
        autoplay={{ delay: 4000 }}
        pagination={{ clickable: true }}
        paginationVariant="circle"
        buttonGroupClassName="hidden"
      >
        {slides?.map((slide, slideIndex) => (
          <SwiperSlide
            className={"carouselItem !w-full px-0 2xl:px-3.5"}
            key={slideIndex}
            style={{
              backgroundColor: (slide?.fields.backgroundColor as unknown as { value: string })
                .value,
            }}
          >
            <div
              className={
                "2xl:px-18 bg-grey-10 mx-auto flex flex-col items-center justify-between rounded-lg px-4 py-5 md:px-10 md:py-6 lg:flex-row lg:px-16 lg:py-8 xl:!w-2/3 xl:py-10"
              }
              style={{
                backgroundColor: (slide?.fields.backgroundColor as unknown as { value: string })
                  .value,
              }}
            >
              {/*Image Wrapper*/}
              <div className="-mr-0.5 w-full pl-4 sm:flex lg:order-2 lg:w-1/2 2xl:-mr-1.5">
                {slide?.fields.media?.fields.file?.url && (
                  <Image
                    priority
                    className="object-contain"
                    src={`https:${slide?.fields.media?.fields.file.url}`}
                    alt={slide?.fields.media?.fields.title!}
                    width={slide?.fields.media?.fields.file?.details?.image?.width!}
                    height={slide?.fields.media?.fields.file?.details?.image?.height!}
                    sizes="(max-width: 1023px) 90vw, 42vw"
                  />
                )}
              </div>
              {/*Textual Content Wrapper*/}
              <div className="w-full shrink-0 lg:order-1 lg:flex lg:w-1/2 lg:max-w-lg lg:items-center xl:max-w-xl">
                <div className="py-4 text-center md:py-6 lg:text-left xl:py-8">
                  <h1 className="mb-4 text-xl font-bold sm:text-xl md:mb-6 md:text-3xl lg:mb-7 lg:pr-20 xl:mb-10 xl:text-3xl 2xl:mb-12 2xl:pr-0 2xl:text-4xl">
                    {slide?.fields.headline &&
                      documentToReactComponents(slide?.fields.headline, {
                        ...defaultDocumentToReactComponentsOptions,
                        renderNode: {
                          [BLOCKS.PARAGRAPH]: (node, children) => (
                            <p className="mb-0">{children}</p>
                          ),
                        },
                      })}
                  </h1>
                  <div className="-mt-1 mb-4 text-lg md:mb-6 md:text-xl lg:mb-7 lg:text-2xl xl:mb-10 2xl:mb-12 2xl:text-2xl">
                    {slide?.fields.bodyText &&
                      documentToReactComponents(
                        slide?.fields.bodyText,
                        defaultDocumentToReactComponentsOptions,
                      )}
                  </div>
                  <div className="-mt-1 mb-2 md:mb-3 lg:mb-3.5 xl:mb-4">
                    {slide?.fields.ctaText && (
                      <OptionalLink href={getPath(slide?.fields.targetPage!)}>
                        <Button className="hover:box-shadow inline-flex transition duration-200 ease-in hover:opacity-80">
                          {slide.fields.ctaText}
                        </Button>
                      </OptionalLink>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Carousel>
    </div>
  );
}
