import cn from "clsx";
import React, { CSSProperties, JSXElementConstructor } from "react";

type Variant = "mediumHeading" | "heading" | "body" | "pageHeading" | "subHeading";

interface TextProps {
  variant?: Variant;
  className?: string;
  style?: CSSProperties;
  children?: React.ReactNode | any;
  html?: string;
}

export function Text({ style, className, variant = "body", children, html }: TextProps) {
  const componentsMap: {
    [P in Variant]: React.ComponentType<any> | string;
  } = {
    body: "p",
    mediumHeading: "h3",
    heading: "h4",
    pageHeading: "h1",
    subHeading: "h2",
  };

  const Component:
    | JSXElementConstructor<any>
    | React.ReactElement<any>
    | React.ComponentType<any>
    | string = componentsMap![variant!];

  const htmlContentProps = html
    ? {
        dangerouslySetInnerHTML: { __html: html },
      }
    : {};

  return (
    <Component
      className={cn(
        {
          "text-sm sm:leading-6 leading-7": variant === "body",
          "text-lg md:text-xl lg:text-2xl 2xl:text-3xl xl:leading-10 font-bold":
            variant === "mediumHeading",
          "text-grey":
            variant === "body" ||
            variant === "mediumHeading" ||
            variant === "heading" ||
            variant === "pageHeading" ||
            variant === "subHeading",
          "text-xl lg:text-2xl font-semibold": variant === "heading",
          "lg:text-3xl text-2xl font-bold": variant === "pageHeading",
          "text-2xl lg:text-3xl xl:text-3xl 2xl:text-4xl  font-bold": variant === "subHeading",
        },
        className,
      )}
      style={style}
      {...htmlContentProps}
    >
      {children}
    </Component>
  );
}
