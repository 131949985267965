"use client";

import cn from "clsx";
import { ReactNode, useState } from "react";

import { getPreviewProps, PreviewOptions } from "@/features/contentful/helpers/get-preview-props";

type CollapseProps = {
  i: number;
  title?: ReactNode;
  content?: ReactNode;
  expanded?: number;
  setExpanded: (value: number | undefined) => void;
  variant?: "gray" | "white" | "transparent";
};

function Collapse({
  i,
  expanded,
  setExpanded,
  title,
  content,
  variant = "white",
  ...previewOptions
}: CollapseProps) {
  const isOpen = i === expanded;
  return (
    <div
      className={cn(
        {
          "shadow-sm": isOpen,
        },
        "border-grey-30 xs:text-left border-b-2 bg-white first:border-t-2 sm:text-left",
      )}
      {...previewOptions}
    >
      <header
        onClick={() => setExpanded(isOpen ? undefined : i)}
        className={cn(
          "relative flex cursor-pointer items-center justify-between py-5 transition-colors md:py-6",
          {
            "px-6 md:px-8 lg:px-10": variant === "white",
          },
        )}
      >
        <h2
          className={cn("pr-2 text-2xl font-semibold leading-relaxed *:mb-0", {
            "md:text-2xl": variant === "gray",
            "md:text-base lg:text-2xl": variant === "transparent",
          })}
        >
          {title}
        </h2>
        <div className="relative flex size-4 shrink-0 items-center justify-center">
          <div className="bg-grey h-0.5 w-full rounded-sm" />
          <div
            className={`bg-grey absolute bottom-0 h-full w-0.5 origin-bottom rounded-sm transition-transform duration-500 ease-in-out ${
              isOpen ? "scale-0" : "scale-100"
            }`}
          />
        </div>
      </header>
      <div
        className={`overflow-hidden transition-all duration-500 ${
          isOpen ? "max-h-fit" : "max-h-0"
        }`}
      >
        <div
          className={cn("text-grey-90 pb-6 text-base leading-7 md:pb-7", {
            "pt-4": variant === "gray",
          })}
        >
          {content}
        </div>
      </div>
    </div>
  );
}

export type AccordionProps = {
  items: {
    title?: ReactNode;
    content?: ReactNode;
  }[];
  variant?: "gray" | "transparent";
  previewEntries?: PreviewOptions[];
  defaultValue?: number;
};

export function Accordion({
  items,
  variant = "gray",
  previewEntries = [],
  defaultValue,
}: AccordionProps) {
  const [expanded, setExpanded] = useState<typeof defaultValue>(defaultValue);
  return (
    <>
      {items?.map((item, index) => (
        <Collapse
          i={index}
          key={index}
          title={item.title}
          content={item.content}
          expanded={expanded}
          setExpanded={setExpanded}
          variant={variant}
          {...getPreviewProps(previewEntries[index])}
        />
      ))}
    </>
  );
}
