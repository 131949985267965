"use client";

import cn from "clsx";
import { PropsWithChildren, useEffect, useRef, useState } from "react";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { Autoplay, Navigation, Pagination, Scrollbar } from "swiper/modules";
import { Swiper } from "swiper/react";
import { SwiperOptions } from "swiper/types";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import "swiper/css/scrollbar";

type CarouselPropsType = {
  className?: string;
  buttonGroupClassName?: string;
  prevActivateId?: string;
  nextActivateId?: string;
  paginationFractionId?: string;
  prevButtonClasses?: string;
  nextButtonClasses?: string;
  buttonSize?: "default" | "small";
  paginationVariant?: "default" | "circle";
  paginationPosition?: "center" | "left" | "right";
  loop?: boolean;
  centeredSlides?: boolean;
  breakpoints?: SwiperOptions["breakpoints"];
  pagination?: SwiperOptions["pagination"];
  navigation?: SwiperOptions["navigation"];
  scrollbar?: SwiperOptions["scrollbar"];
  autoplay?: SwiperOptions["autoplay"];
  type?: "rounded" | "circle" | "list";
  isFraction?: boolean;
  spaceBetween?: string | number;
  slidesPerView?: number | "auto";
};

export function Carousel({
  children,
  className = "",
  buttonGroupClassName = "",
  prevActivateId = "",
  nextActivateId = "",
  paginationFractionId = "",
  prevButtonClasses = "left-0",
  nextButtonClasses = "right-0",
  buttonSize = "default",
  paginationVariant = "default",
  paginationPosition,
  breakpoints,
  loop = true,
  navigation = true,
  pagination = false,
  autoplay = false,
  type = "circle",
  isFraction = false,
  slidesPerView = "auto",
  spaceBetween,
  ...props
}: PropsWithChildren<CarouselPropsType>) {
  const prevRef = useRef<HTMLButtonElement>(null);
  const nextRef = useRef<HTMLButtonElement>(null);
  const [prevButton, setPrevButton] = useState(prevRef.current);
  const [nextButton, setNextButton] = useState(nextRef.current);
  const classPagination = paginationPosition ? `pagination-${paginationPosition}` : "";
  const nextButtonClassName = cn(
    "duration-250 hover:bg-grey-90 shadow-navigation absolute flex size-7 translate-x-1/2 items-center justify-center rounded bg-white text-sm text-black transition hover:text-white focus:outline-none md:text-base lg:size-8 lg:text-lg",
    {
      "rounded-full": type === "circle",
      "lg:w-9 lg:h-9 xl:w-10 xl:h-10 3xl:w-12 3xl:h-12 text-sm md:text-base lg:text-xl 3xl:text-2xl":
        buttonSize === "default",
      "!static": type === "list",
    },
    nextButtonClasses,
  );

  useEffect(() => {
    setPrevButton(prevRef.current);
    setNextButton(nextRef.current);
  }, []);

  const prevButtonClassName = cn(
    "duration-250 hover:bg-grey-90 shadow-navigation absolute flex size-7 -translate-x-1/2 items-center justify-center rounded bg-white text-sm text-black transition hover:text-white focus:outline-none md:text-base lg:size-8 lg:text-lg",
    {
      "rounded-full": type === "circle",
      "lg:w-9 lg:h-9 xl:w-10 xl:h-10 3xl:w-12 3xl:h-12 text-sm md:text-base lg:text-xl 3xl:text-2xl":
        buttonSize === "default",
      "!static": type === "list",
    },
    prevButtonClasses,
  );
  return (
    <div
      className={`carouselWrapper relative ${className} ${classPagination} ${
        paginationVariant === "circle" ? "dotsCircle" : ""
      } ${type === "list" ? "!static" : ""}`}
    >
      <Swiper
        modules={[Navigation, Autoplay, Pagination, Scrollbar]}
        loop={loop}
        slidesPerView={slidesPerView}
        spaceBetween={spaceBetween}
        autoplay={autoplay}
        breakpoints={breakpoints}
        pagination={pagination}
        navigation={
          navigation
            ? {
                prevEl: prevActivateId.length ? `#${prevActivateId}` : prevButton, // Assert non-null
                nextEl: nextActivateId.length ? `#${nextActivateId}` : nextButton, // Assert non-null
              }
            : {}
        }
        {...props}
      >
        {children}
      </Swiper>
      {(Boolean(navigation) || Boolean(isFraction)) && (
        <div
          className={cn(`absolute top-2/4 z-10 flex w-full items-center ${buttonGroupClassName}`, {
            "": type === "list",
          })}
        >
          {prevActivateId.length > 0 ? (
            <button className={prevButtonClassName} id={prevActivateId} aria-label="prev-button">
              <IoIosArrowBack />
            </button>
          ) : (
            <button ref={prevRef} className={prevButtonClassName} aria-label="prev-button">
              <IoIosArrowBack />
            </button>
          )}

          {Boolean(isFraction) && (
            <div className="!w-auto text-center text-xs sm:text-base" id={paginationFractionId} />
          )}

          {nextActivateId.length > 0 ? (
            <button className={nextButtonClassName} id={nextActivateId} aria-label="next-button">
              <IoIosArrowForward />
            </button>
          ) : (
            <button ref={nextRef} className={nextButtonClassName} aria-label="next-button">
              <IoIosArrowForward />
            </button>
          )}
        </div>
      )}
    </div>
  );
}
